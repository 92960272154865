#root {
    min-height: 100vh;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.slider img{
    width: 250px;
    height: auto;
    object-fit: cover;
    padding: 10px;
    transition: 1s ease-in-out;
}

.slick-current img {
    transform: scale(1.05);
}
